import request from "@/utils/request";

const api = {
  getDevices: "/dsmcore/api/device/list", // 查询设备列表的数据
  distributeDevice: "/dsmcore/api/device/distribution", // 分配设备到项目经理
  getManager: "/dsmcore/api/worker/manager", // 查询项目经理信息
  getCompanies: "/dsmcore/api/company/companylist", // 查询分公司列表
  storeDevice: "", // 设备入库
  getStock: "/dsmcore/api/device/stock", // 查询设备库存
  getbindAction: "/dsmcore/api/device/bindaction", // 设备绑定解绑记录
  getCompaniesStore: "/dsmcore/api/companystore/companystorelist", //查询分公司门店列表
  getDcountbc: "/dsmcore/api/count/dcountbc", // 设备统计分析按组织统计
  dcountwboss: "/dsmcore/api/count/dcountwboss", // 设备统计分析按负责人统计
  dcountbchistory: "/dsmcore/api/count/dcountbchistory", //设备统计分析按组织统计-历史分析
  dcountwbosshistory: "/dsmcore/api/count/dcountwbosshistory", //设备统计分析按负责人统计-历史分析
  deldevice: "/dsmcore/api/device/deldevice", //删除设备信息
  updatedevice: "/dsmcore/api/device/updatedevice", //修改设备信息
};

export function GetDevices(parameter) {
  return request({
    url: api.getDevices,
    method: "post",
    params: parameter,
  });
}
export function DistributeDevice(parameter) {
  return request({
    url: api.distributeDevice,
    method: "post",
    params: parameter,
  });
}
export function GetManager(parameter) {
  return request({
    url: api.getManager,
    method: "post",
    params: parameter,
  });
}
export function GetCompanies(parameter) {
  return request({
    url: api.getCompanies,
    method: "post",
    params: parameter,
  });
}

export function StoreDevice(parameter) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000);
  });
}

export function GetStock(parameter) {
  return request({
    url: api.getStock,
    method: "post",
    params: parameter,
  });
}

export function GetbindAction(parameter) {
  return request({
    url: api.getbindAction,
    method: "post",
    params: parameter,
  });
}

export function GetCompaniesStore(parameter) {
  return request({
    url: api.getCompaniesStore,
    method: "post",
    params: parameter,
  });
}
export function getDcountbc(parameter) {
  return request({
    url: api.getDcountbc,
    method: "post",
    params: parameter,
  });
}
export function getDcountwboss(parameter) {
  return request({
    url: api.dcountwboss,
    method: "post",
    params: parameter,
  });
}

export function getDcountbchistory(parameter) {
  return request({
    url: api.dcountbchistory,
    method: "post",
    params: parameter,
  });
}

export function getDcountwbosshistory(parameter) {
  return request({
    url: api.dcountwbosshistory,
    method: "post",
    params: parameter,
  });
}

export function Deldevice(parameter) {
  return request({
    url: api.deldevice,
    method: "post",
    params: parameter,
  });
}

export function Updatedevice(parameter) {
  return request({
    url: api.updatedevice,
    method: "post",
    params: parameter,
  });
}
