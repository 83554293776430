import request from "@/utils/request";

/**
 * 地图组件中使用的查询地市的可选项
 * 后续改造成接口返回数据
 * 格式需要按照现有格式
 * { 
 *   value: "",
 *   label: "",
 *   children: [],
 * }
 */
const api = {
  getArea: "/dsmcore/api/worksite/area", // 查询工地区域集合
};

export function GetArea() {
  return request({
    url: api.getArea,
    method: "post",
  });
}

// export Cities;

// const Cities = [
//   {
//     value: "北京市",
//     label: "北京市",
//     children: [
//       {
//         value: "北京市",
//         label: "北京市",
//         children: [
//           {
//             value: "西城区",
//             label: "西城区",
//           },
//           {
//             value: "东城区",
//             label: "东城区",
//           },
//           {
//             value: "海淀区",
//             label: "海淀区",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     value: "河南省",
//     label: "河南省",
//     children: [
//       {
//         value: "郑州市",
//         label: "郑州市",
//         children: [
//           {
//             value: "金水区",
//             label: "金水区",
//           },
//           {
//             value: "中原区",
//             label: "中原区",
//           },
//         ],
//       },
//     ],
//   },
// ];
