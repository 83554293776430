import request from "@/utils/request";

const api = {
  getWorkers: "/dsmcore/api/worker/workerlist", // 查询列表人员信息
  getWorkerAttend: "/dsmcore/api/worker/attendance", // 查询工人考勤信息
  getAttendCalender: "/dsmcore/api/worker/calendar", // 查询工人考勤日历
  getWorkerType: "/dsmcore/api/worker/type", //获取工人工种
  getWorkerPost: "/dsmcore/api/worker/post", //获取工人岗位
  addWorker: "/dsmcore/api/worker/addworker", //添加工人信息
  updateworker: "/dsmcore/api/worker/updateworker", //修改工人信息
  delworker: "/dsmcore/api/worker/delworker", //删除工人信息
};

export function GetWorkers(parameter) {
  return request({
    url: api.getWorkers,
    method: "post",
    params: parameter,
  });
}

export function GetWorkerAttend(parameter) {
  return request({
    url: api.getWorkerAttend,
    method: "post",
    params: parameter,
  });
}

export function CetAttendCalender(parameter) {
  return request({
    url: api.getAttendCalender,
    method: "post",
    params: parameter,
  });
}

export function GetWorkerType() {
  return request({
    url: api.getWorkerType,
    method: "post",
  });
}
export function GetWorkerPost() {
  return request({
    url: api.getWorkerPost,
    method: "post",
  });
}

export function AddWorker(params, data) {
  return request({
    url: api.addWorker,
    method: "post",
    params: params,
    data: data,
    // "Content-type": "multipart / form - data",
  });
}
export function Updateworker(params, data) {
  return request({
    url: api.updateworker,
    method: "post",
    params: params,
    data: data,
    // "Content-type": "multipart / form - data",
  });
}

export function Delworker(params) {
  return request({
    url: api.delworker,
    method: "post",
    params,
  });
}
