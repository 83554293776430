<template>
  <div style="display: inline-block; margin-left: 12px">
    <a-button type="plain" icon="setting" @click="handleSet">表头设置</a-button>

    <a-modal
      class="tips-modal"
      title="表头设置"
      okText="确定"
      cancelText="取消"
      :visible="visible"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <a-checkbox
        :indeterminate="indeterminate"
        :checked="checkAll"
        @change="onCheckAllChange"
      >
        全选
      </a-checkbox>
      <a-checkbox-group v-model="checkedList" @change="onChange">
        <a-checkbox
          v-for="item in columns"
          :key="item.dataIndex"
          :value="item.dataIndex"
          bordered
          style="margin: 10px 0"
          >{{ item.title ? item.title : item.slots.title }}</a-checkbox
        >
      </a-checkbox-group>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "ColumnsSet",
  props: {
    columns: {
      type: Array,
      default: [],
    },
    tableName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      checkedList: [],
      indeterminate: true,
      checkAll: false,
    };
  },
  created() {
    if (localStorage.getItem(this.tableName + "ColumnsSet")) {
      this.checkedList = JSON.parse(
        localStorage.getItem(this.tableName + "ColumnsSet")
      );
      this.onChange(this.checkedList);
    } else {
      this.checkedList = this.getAllKeys();
      this.indeterminate = false;
      this.checkAll = true;
    }
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < this.columns.length;
      this.checkAll = checkedList.length === this.columns.length;
    },
    getAllKeys() {
      let allKeys = [];
      this.columns.forEach((el) => allKeys.push(el.dataIndex));
      return allKeys;
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked;
      this.indeterminate = false;
      this.checkedList = e.target.checked ? this.getAllKeys() : [];
    },
    handleSet() {
      this.visible = true;
    },
    exportOk() {
      if (!this.checkedList.length) {
        this.$message.warning("表格列不能为空");
        return false;
      }
      this.visible = false;
      let itemData = this.checkedList;
      localStorage.setItem(
        this.tableName + "ColumnsSet",
        JSON.stringify(itemData)
      );
      this.$emit("changeColumns");
    },
    exportCancel() {
      this.visible = false;
    },
  },
};
</script>
