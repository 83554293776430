import request from "@/utils/request";

const api = {
  getmaterialTypelist: "/dsmcore/api/materialType/list", // 查询材料类别
  addmaterialType: "/dsmcore/api/materialType/add", //添加材料类别

  updatematerialType: "/dsmcore/api/materialType/update", //更新材料类别
  deletematerialType: "/dsmcore/api/materialType/delete", //删除材料类别
  getmaterialBrandList: "/dsmcore/api/materialBrand/list", //查询材料品牌接口
  addmaterialBrand: "/dsmcore/api/materialBrand/add", //添加材料品牌接口
  updatematerialBrand: "/dsmcore/api/materialBrand/update", //更新材料品牌接口
  deletematerialBrand: "/dsmcore/api/materialBrand/delete", //删除材料品牌接口
  getmaterialSupplier: "/dsmcore/api/supplier/list", //查询供应商接口
  addmaterialSupplier: "/dsmcore/api/supplier/add", //添加供应商接口
  updatematerialSupplier: "/dsmcore/api/supplier/update", //更新供应商接口
  deletematerialSupplier: "/dsmcore/api/supplier/delete", //删除供应商接口
  getmaterialselectById: "/dsmcore/api/material/selectById", //根据id查询材料信息接口
  getmateriallist: "/dsmcore/api/material/list", //查询材料信息接口
  addmaterial: "/dsmcore/api/material/add", //添加材料信息接口
  updatematerial: "/dsmcore/api/material/update", //更新材料信息接口
  deletematerial: "/dsmcore/api/material/delete", //删除材料信息接口
  getworkProcelist: "/dsmcore/api/workProce/list", //查询施工工序列表接口
  addworkProce: "/dsmcore/api/workProce/add", //添加施工工序接口
  updateworkProce: "/dsmcore/api/workProce/update", //更新施工工序接口
  deleteworkProce: "/dsmcore/api/workProce/delete", //删除施工工序接口
  getprojectTypeList: "/dsmcore/api/projectType/list", //查询施工项类别接口
  addprojectType: "/dsmcore/api/projectType/add", //添加施工项类别接口
  updateprojectType: "/dsmcore/api/projectType/update", //更新施工项类别接口
  deleteprojectType: "/dsmcore/api/projectType/delete", //删除施工项类别接口
  getprojectList: "/dsmcore/api/project/list", //查询施工项列表
  addproject: "/dsmcore/api/project/add", //添加施工项信息接口
  updateproject: "/dsmcore/api/project/update", //更新施工项信息
  deleteproject: "/dsmcore/api/project/delete", //删除施工项信息
  getpackageTypeList: "/dsmcore/api/packageType/list", //查询套餐类别接口
  addpackageType: "/dsmcore/api/packageType/add", //添加套餐类别接口
  updatepackageType: "/dsmcore/api/packageType/update", //更新套餐类别接口
  deletepackageType: "/dsmcore/api/packageType/delete", //删除套餐类别接口
  getextraChargeList: "/dsmcore/api/extraCharges/list", //查询额外收费列表接口
  addextraCharges: "/dsmcore/api/extraCharges/add", //添加额外收费项信息接口
  updateextraCharges: "/dsmcore/api/extraCharges/update", //更新额外收费项信息
  deleteextraCharges: "/dsmcore/api/extraCharges/delete", //删除额外收费项信息
  getprojectMaterialList: "/dsmcore/api/projectMaterial/list", //查询施工物料列表接口
  addprojectMaterial: "/dsmcore/api/projectMaterial/addList", //添加施工物料信息
  updateprojectMaterial: "/dsmcore/api/projectMaterial/updateBatch", //更新施工物料信息
  deleteprojectMaterial: "/dsmcore/api/projectMaterial/delete", //删除施工材料信息
  materialTypeSearch: "/dsmcore/api/materialType/query", //查询材料类别可选择数据
  materialBrandSearch: "/dsmcore/api/materialBrand/query", //查询材料品牌可选择数据
};

export function GetMaterialTypeList(parameter) {
  return request({
    url: api.getmaterialTypelist,
    method: "post",
    params: parameter,
  });
}
export function AddMaterialType(parameter) {
  return request({
    url: api.addmaterialType,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function UpdateMaterialType(parameter) {
  return request({
    url: api.updatematerialType,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteMaterialType(parameter) {
  return request({
    url: api.deletematerialType,
    method: "post",
    params: parameter,
  });
}
export function GetMaterialBrandList(parameter) {
  return request({
    url: api.getmaterialBrandList,
    method: "post",
    params: parameter,
  });
}
export function AddMaterialBrand(parameter) {
  return request({
    url: api.addmaterialBrand,
    method: "post",
    params: parameter,
  });
}
export function UpdateMaterialBrand(parameter) {
  return request({
    url: api.updatematerialBrand,
    method: "post",
    data: parameter,
  });
}
export function DeleteMaterialBrand(parameter) {
  return request({
    url: api.deletematerialBrand,
    method: "post",
    params: parameter,
  });
}

export function GetMaterialSupplier(parameter) {
  return request({
    url: api.getmaterialSupplier,
    method: "post",
    params: parameter,
  });
}
export function AddMaterialSupplier(parameter) {
  return request({
    url: api.addmaterialSupplier,
    method: "post",
    params: parameter,
  });
}
export function UpdateMaterialSupplier(parameter) {
  return request({
    url: api.updatematerialSupplier,
    method: "post",
    params: parameter,
  });
}
export function DeleteMaterialSupplier(parameter) {
  return request({
    url: api.deletematerialSupplier,
    method: "post",
    params: parameter,
  });
}
export function GetMaterialselectById(parameter) {
  return request({
    url: api.getmaterialselectById,
    method: "post",
    params: parameter,
  });
}
export function GetMaterialList(parameter) {
  return request({
    url: api.getmateriallist,
    method: "post",
    params: parameter,
  });
}
export function AddMaterial(parameter) {
  return request({
    url: api.addmaterial,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateMaterial(parameter) {
  return request({
    url: api.updatematerial,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteMaterial(parameter) {
  return request({
    url: api.deletematerial,
    method: "get",
    params: parameter,
  });
}
export function GetWorkProceList(parameter) {
  return request({
    url: api.getworkProcelist,
    method: "post",
    params: parameter,
  });
}
export function AddWorkProce(parameter) {
  return request({
    url: api.addworkProce,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdatWorkProce(parameter) {
  return request({
    url: api.updateworkProce,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteWorkProce(parameter) {
  return request({
    url: api.deleteworkProce,
    method: "post",
    params: parameter,
  });
}
export function GetProjectTypeList(parameter) {
  return request({
    url: api.getprojectTypeList,
    method: "post",
    params: parameter,
  });
}
export function AddProjectType(parameter) {
  return request({
    url: api.addprojectType,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
export function UpdateProjectType(parameter) {
  return request({
    url: api.updateprojectType,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteProjectType(parameter) {
  return request({
    url: api.deleteprojectType,
    method: "post",
    params: parameter,
  });
}
export function GetProjectList(parameter) {
  return request({
    url: api.getprojectList,
    method: "post",
    params: parameter,
  });
}
export function AddProject(parameter) {
  return request({
    url: api.addproject,
    method: "post",
    data: parameter,
  });
}
export function UpdateProject(parameter) {
  return request({
    url: api.updateproject,
    method: "post",
    data: parameter,
  });
}
export function DeleteProject(parameter) {
  return request({
    url: api.deleteproject,
    method: "post",
    params: parameter,
  });
}
export function GetPackageTypeList(parameter) {
  return request({
    url: api.getpackageTypeList,
    method: "post",
    params: parameter,
  });
}
export function AddPackageType(parameter) {
  return request({
    url: api.addpackageType,
    method: "post",
    params: parameter,
  });
}
export function UpdatePackageType(parameter) {
  return request({
    url: api.updatepackageType,
    method: "post",
    data: parameter,
  });
}
export function DeletePackageType(parameter) {
  return request({
    url: api.deletepackageType,
    method: "post",
    params: parameter,
  });
}
export function GetExtraChargeList(parameter) {
  return request({
    url: api.getextraChargeList,
    method: "post",
    params: parameter,
  });
}
export function AddExtraCharges(parameter) {
  return request({
    url: api.addextraCharges,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateExtraCharges(parameter) {
  return request({
    url: api.updateextraCharges,
    method: "post",
    data: parameter,
  });
}
export function DeleteExtraCharges(parameter) {
  return request({
    url: api.deleteextraCharges,
    method: "post",
    params: parameter,
  });
}
export function GetProjectMaterialList(parameter) {
  return request({
    url: api.getprojectMaterialList,
    method: "post",
    params: parameter,
  });
}
export function AddProjectMaterial(parameter) {
  return request({
    url: api.addprojectMaterial,
    method: "post",
    data: parameter,
  });
}
export function UpdateProjectMaterial(parameter) {
  return request({
    url: api.updateprojectMaterial,
    method: "post",
    data: parameter,
  });
}
export function DeleteProjectMaterial(parameter) {
  return request({
    url: api.deleteprojectMaterial,
    method: "post",
    params: parameter,
  });
}
export function MaterialTypeSearch(parameter) {
  return request({
    url: api.materialTypeSearch,
    method: "post",
    params: parameter,
  });
}
export function MaterialBrandSearch(parameter) {
  return request({
    url: api.materialBrandSearch,
    method: "post",
    params: parameter,
  });
}
