// 绑定状态
const SiteBindStatus = [
  { value: "0", label: "全部" },
  { value: "1", label: "未绑定" },
  { value: "2", label: "已绑定" },
];
// 设备状态
const SiteDeviceStatus = [
  { value: "0", label: "全部" },
  // { value: "", label: "在线" },
  { value: "1", label: "在线" },
  // { value: "2", label: "不在线" },
  { value: "2", label: "离线" },
];
// 工地状态
const SiteStatus = [
  { value: "0", label: "全部" },
  // { value: "", label: "施工中" },
  { value: "1", label: "施工中" },
  { value: "2", label: "未开工" },
  { value: "3", label: "合同终止" },
  { value: "4", label: "施工结束" },
];
// 摄像头
const Camera = [
  { value: "", label: "全部" },
  { value: "1", label: "有" },
  { value: "2", label: "无" },
];
const NoWorks = [
  { value: "0", label: "全部" },
  { value: "1", label: "连续3天" },
  { value: "2", label: "连续3-7天" },
  { value: "3", label: "连续7天以上" },
  { value: "4", label: "累计10天" },
  { value: "5", label: "累计10-30天" },
  { value: "6", label: "累计30天以上" },
];
const NoWorksSiteList = [
  { value: "0", label: "全部" },
  { value: "1", label: "连续3天" },
  { value: "2", label: "连续3-7天" },
  { value: "3", label: "连续7天以上" },
  { value: "4", label: "累计1-9天" },
  { value: "5", label: "累计10-19天" },
  { value: "6", label: "累计20-29天" },
  { value: "7", label: "累计30-39天" },
  { value: "8", label: "累计40天以上" },
];
const WorkType = [{ value: "", label: "全部" }];
const WorkTypeWorkAttent = [
  { value: "", label: "全部" },
  { value: "402", label: "瓦工工人" },
  { value: "403", label: "木工工人" },
  { value: "404", label: "油工工人" },
  { value: "405", label: "辅助工人" },
  { value: "406", label: "水电工人" },
];

const WorkPost = [{ value: "", label: "全部" }];
const WorkPostWorkAttent = [
  { value: "", label: "全部" },
  { value: "1", label: "工程管家" },
  { value: "2", label: "项目经理" },
  { value: "3", label: "工人" },
  { value: "qita", label: "其他" },
];
const DeviceWorkPost = [
  { value: "", label: "全部" },
  { value: "1", label: "工程管家" },
  { value: "2", label: "项目经理" },
];
const WorkerSex = [
  { value: "男", label: "男" },
  { value: "女", label: "女" },
];
const DeviceStatus = [
  { value: "0", label: "全部" },
  { value: "1", label: "在线" },
  { value: "2", label: "离线" },
  { value: "3", label: "未启用" },
  // { value: "4", label: "故障" },
  { value: "5", label: "备用" },
  // { value: "6", label: "入库" },
];
// 设备库存-设备型号
const DeviceXinghao = [
  { value: "", label: "全部" },
  { value: "1", label: "8909摄像头" },
  { value: "2", label: "450 摄像头" },
];
// 设备库存-设备版本号
const DeviceBanbenhao = [{ value: "", label: "全部" }];
const AlarmEvents = [
  { value: "", label: "全部" },
  // { value: "cameraproblem", label: "摄像头歪曲" },
  { value: "noclothes", label: "未穿工服" },
  { value: "smoking", label: "有人吸烟" },
  { value: "haveclothes", label: "穿工服" },
  { value: "hacnoa", label: "上班未打卡" },
];
const SiteNeatLevel = [
  { value: "", label: "全部" },
  { value: "1", label: "优" },
  { value: "2", label: "良" },
  { value: "3", label: "差" },
  { value: "4", label: "摄像头歪曲" },
];
const SiteServiceType = [
  { value: "全部", label: "全部" },
  { value: "套装", label: "套装" },
  { value: "汇巢", label: "汇巢" },
  { value: "全包圆", label: "全包圆" },
  { value: "峰格汇", label: "峰格汇" },
];
const SiteAttendanceType = [
  { value: "", label: "全部" },
  { value: "1", label: "已打卡" },
  { value: "2", label: "未打卡" },
];

const FaceStatus = [
  { value: "", label: "全部" },
  { value: "1", label: "已录人脸" },
  { value: "2", label: "未录人脸" },
];
const SiteModelStatus = [
  { value: "", label: "全部" },
  { value: "1", label: "是" },
  { value: "2", label: "否" },
];
const CompanyList = [{ value: "", label: "全部" }];
// 派工状态
const SitePaiGongStatus = [
  { value: "", label: "全部" },
  { value: "1", label: "已派工" },
  { value: "2", label: "未派工" },
];
// 纠正状态
const SitLogStatus = [
  { value: "", label: "全部" },
  { value: "1", label: "未纠正" },
  { value: "2", label: "纠正中" },
  { value: "3", label: "已纠正" },
  { value: "4", label: "已取消" },
];
// 纠正事件
const SitLogType = [
  { value: "", label: "全部" },
  { value: "1", label: "无人吸烟/其他" },
  { value: "2", label: "有穿工服/其他" },
  { value: "3", label: "摄像头歪曲" },
  { value: "4", label: "整洁度（优）" },
  { value: "5", label: "整洁度（良）" },
  { value: "6", label: "整洁度（差）" },
  { value: "7", label: "未穿工服" },
];
// 原纠正事件
const OldLogType = [
  { value: "", label: "全部" },
  { value: "1", label: "有人吸烟" },
  { value: "2", label: "未穿工服" },
  { value: "3", label: "摄像头歪曲" },
  { value: "4", label: "整洁度（优）" },
  { value: "5", label: "整洁度（良）" },
  { value: "6", label: "整洁度（差）" },
  { value: "7", label: "穿工服" },
];
// 最新工地整洁度
const SitSampleLevel = [
  { value: "", label: "全部" },
  { value: "1", label: "摄像头歪曲" },
  { value: "2", label: "优" },
  { value: "3", label: "良" },
  { value: "4", label: "差" },
];
// 是否员工
const SiteEventworkerType = [
  { value: "0", label: "全部" },
  { value: "1", label: "员工" },
  { value: "2", label: "非员工" },
];
// 考勤类型
const AttendanceType = [
  { value: "", label: "全部" },
  { value: "1", label: "正常考勤" },
  { value: "2", label: "未打下班卡" },
];
const WorkerAddPost = [];
const WorkerAddType = [];
const QuotationType = [
  { value: "1", label: "量房前报价" },
  { value: "2", label: "量房后报价" },
];
// 接单情况
const AcceptOrderState = [
  { value: "0", label: "待接单" },
  { value: "1", label: "已拒接" },
  { value: "2", label: "已接单" },
];
// 发货进度
const LogisticsProgress = [
  { value: "0", label: "待处理" },
  { value: "1", label: "发货中" },
  { value: "2", label: "已送达" },
  { value: "3", label: "已验收" },
];
// 退换货-审核状态
const ExamineState = [
  { value: "0", label: "待审核" },
  { value: "1", label: "审核通过" },
  { value: "2", label: "审核拒绝" },
];
// 物料管理-下单状态
const cmMStateList = [
  { value: 1, label: "待下单" },
  { value: 2, label: "已下单" },
  { value: 3, label: "待发货" },
  { value: 4, label: "已发货" },
  { value: 5, label: "待验收" },
  { value: 6, label: "已验收" },
  { value: 7, label: "待退货" },
];
// 签约订单--进度状态
const signingprogress = [
  { value: 1, label: "默认" },
  { value: 2, label: "定金" },
  { value: 3, label: "签约" },
];
export const SelectOptions = {
  SiteBindStatus,
  NoWorks,
  SiteStatus,
  WorkType,
  WorkPost,
  WorkerSex,
  DeviceStatus,
  DeviceXinghao,
  DeviceBanbenhao,
  AlarmEvents,
  SiteNeatLevel,
  SiteServiceType,
  SiteAttendanceType,
  SiteDeviceStatus,
  FaceStatus,
  SiteModelStatus,
  CompanyList,
  Camera,
  SitePaiGongStatus,
  SitLogStatus,
  SitLogType,
  SitSampleLevel,
  SiteEventworkerType,
  OldLogType,
  AttendanceType,
  DeviceWorkPost,
  WorkPostWorkAttent,
  NoWorksSiteList,
  WorkTypeWorkAttent,
  WorkerAddPost,
  WorkerAddType,
  QuotationType,
  AcceptOrderState,
  LogisticsProgress,
  ExamineState,
  cmMStateList,
  signingprogress,
};
